import React from 'react';
import api from '../apis/api';
import './Filter.css';

class Filter extends React.Component {
  state = {
    // populate form with these
    genreList: [],
    languageList: [],
    countryList: [],

    genres: [],
    languages: [],
    countries: [],
    start_yr: 0,
    end_yr: 0,
  };

  async componentDidMount() {
    const genreFilters = await api.get('/genres');
    const countryFilters = await api.get('/countries');
    const languageFilters = await api.get('/languages');
    this.setState({ genreList: genreFilters.data.data });
    this.setState({ languageList: languageFilters.data.data });
    this.setState({ countryList: countryFilters.data.data });
  }

  onInputChange = (event, type) => {
    let current = this.state[type];

    if (event.target.checked) {
      current.push(event.target.value);
    } else {
      const index = current.indexOf(event.target.value);
      current.splice(index, 1);
    }

    this.setState({ [type]: current });
  };

  onFormSubmit = (event) => {
    event.preventDefault();
    const filter = {
      genres: this.state.genres,
      languages: this.state.languages,
      countries: this.state.countries,
      start_yr: this.state.start_yr,
      end_yr: this.state.end_yr || 9000,
    };
    this.props.onFilterSubmit(filter);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onFormSubmit}>
          <div className='filter'>
            <h4>START YEAR</h4>
            <input type='text' onChange={(e) => this.setState({ start_yr: e.target.value })} />

            <h4>END YEAR</h4>
            <input type='text' onChange={(e) => this.setState({ end_yr: e.target.value })} />

            <h4>GENRES</h4>
            {this.state.genreList.map((item, index) => (
              <div key={index}>
                <input type='checkbox' value={item.category} onChange={(e) => this.onInputChange(e, 'genres')} />
                <label>{item.category}</label>
              </div>
            ))}

            <h4>COUNTRIES</h4>
            {this.state.countryList.map((item, index) => (
              <div key={index}>
                <input type='checkbox' value={item.name} onChange={(e) => this.onInputChange(e, 'countries')} />
                <label>{item.name}</label>
              </div>
            ))}

            <h4>LANGUAGES</h4>
            {this.state.languageList.map((item, index) => (
              <div key={index}>
                <input type='checkbox' value={item.name} onChange={(e) => this.onInputChange(e, 'languages')} />
                <label>{item.name}</label>
              </div>
            ))}
          </div>

          <input type='submit' value='Filter' />
        </form>
      </div>
    );
  }
}

export default Filter;
