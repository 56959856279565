import React from 'react';
import api from '../apis/api';
import Search from './Search';
import Filter from './Filter';
import MovieList from './MovieList';
import logo from './logo.png';
import './App.css';

class App extends React.Component {
  state = {
    foundMovies: [],
    page: 1,
  };

  async componentDidMount() {
    const response = await api.get('/movies');
    this.setState({
      foundMovies: response.data.data,
    });
  }

  onTermSubmit = async (term) => {
    const response = await api.get('/movies/search', {
      params: {
        title: term,
        page: this.state.page,
      },
    });

    this.setState({
      foundMovies: response.data.data,
    });
  };

  onFilterSubmit = async (filters) => {
    const response = await api.get('/movies', {
      params: {
        country: filters.countries.join(','),
        language: filters.languages.join(','),
        genre: filters.genres.join(','),
        start_yr: filters.start_yr,
        end_yr: filters.end_yr,
        page: this.state.page,
      },
    });

    this.setState({
      foundMovies: response.data.data,
    });
  };

  render() {
    return (
      <div className='container'>
        <div>
          <a href='/'>
            <img className='logo' src={logo} alt='logo'></img>
          </a>
          <Search onTermSubmit={this.onTermSubmit} />
          <Filter onFilterSubmit={this.onFilterSubmit} />
        </div>
        <MovieList movies={this.state.foundMovies} />
      </div>
    );
  }
}

export default App;
