import React from 'react';

class Search extends React.Component {
  state = { term: '' };

  onInputChange = (event) => {
    this.setState({ term: event.target.value });
  };

  onFormSubmit = (event) => {
    event.preventDefault();
    this.props.onTermSubmit(this.state.term);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onFormSubmit}>
          <input type='text' placeholder='Enter title..' onChange={this.onInputChange}></input>
          <input type='submit' value='Search' />
        </form>
      </div>
    );
  }
}

export default Search;
