import React from 'react';
import MovieItem from './MovieItem';
import './MovieList.css';

class MovieList extends React.Component {
  render() {
    const list = this.props.movies.map((movie) => {
      return <MovieItem movie={movie} />;
    });
    return <div className='movie-list'>{list}</div>;
  }
}

export default MovieList;
