import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../apis/api';
import './VideoList.css';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class VideoList extends React.Component {
  state = {
    movie: [],
    videos: [],
    movieLoaded: false,
    videosLoaded: false,
  };

  async componentDidMount() {
    let { id } = this.props.params;
    await this.fetchMovieInfo(id);
    await this.fetchMovieVideos(id);
  }

  async fetchMovieVideos(id) {
    const response = await api.get(`/videos/movie/${id}/`);
    if (response.status === 200) {
      this.setState({ videosLoaded: true });
      this.setState({ videos: response.data.data });
    } else {
      this.setState({ videosLoaded: false });
    }
  }

  async fetchMovieInfo(id) {
    const response = await api.get(`/movies/movie/${id}/`);
    if (response.status === 200) {
      this.setState({ movieLoaded: true });
      this.setState({ movie: response.data.data });
    } else {
      this.setState({ movieLoaded: false });
    }
  }

  getImdbUrl = (id) => {
    let fixedLengthId = String(id).padStart(7, '0');
    return `https://www.imdb.com/title/tt${fixedLengthId}`;
  };

  render() {
    const list = this.state.videos.map((video) => {
      return (
        <React.Fragment>
          <iframe
            width='560'
            height='315'
            src={`https://www.youtube.com/embed/${video.youtube_id}`}
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          />
        </React.Fragment>
      );
    });

    let movieInfo = 'Loading Movie Info...';
    if (this.state.movieLoaded && this.state.movie.length === 0) {
      movieInfo = `No movie found for id ${this.props.params.id}`;
    } else if (this.state.movieLoaded) {
      document.title = `${this.state.movie[0].title} - movierecaps.tv`;
      movieInfo = (
        <div>
          <h1>
            {this.state.movie[0].title} ({this.state.movie[0].year})
          </h1>
          <a href={this.getImdbUrl(this.state.movie[0].imdb_id)}>
            <img src={this.state.movie[0].poster_url} alt={this.state.movie[0].title + ' poster'}></img>
          </a>
        </div>
      );
    }

    let videos = 'Loading Videos...';
    if (this.state.videosLoaded && this.state.videos.length === 0) {
      movieInfo = `No videos found`;
    } else if (this.state.videosLoaded) {
      videos = <div className='video-list'>{list}</div>;
    }

    return (
      <div className='container'>
        {movieInfo}
        {videos}
      </div>
    );
  }
}

export default withParams(VideoList);
