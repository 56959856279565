import React from 'react';
import './MovieItem.css';

class MovieItem extends React.Component {
  render() {
    return (
      <a href={`/movies/${this.props.movie.imdb_id}`} target='_blank' rel='noreferrer'>
        <div className='card tooltip' key={this.props.movie.index}>
          <span className='tooltiptext'>
            {this.props.movie.title} ({this.props.movie.year})
          </span>
          <img className='poster ' src={this.props.movie.poster_url} alt={this.props.movie.title + ' poster'}></img>
        </div>
      </a>
    );
  }
}

export default MovieItem;
